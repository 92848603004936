export default {
    partnersTitle:        "Наши единомышленники",
    reviewsTitle:         "Отзывы о работе платформы",
    specialistsTitle:     "Ведущие специалисты",
    specialistsDescr:     "Ваша эмоциональная стабильность – наша главная цель. Поэтому в нашей команде работают только сертифицированные специалисты с опытом работы, которых отобрали наши ведущие специалисты. Мы гарантируем профессионализм психологов строгим отбором.",
    specialistsBtn:       "Обратиться за поддержкой",
    resultTitle:          "Ваша заявка успешно зарегистрирована на сайте «Розкажи мені»",
    resultDescript:       "Наші фахівці вже взяли його в роботу. Очікуйте на зворотний зв’язок у Найближчий час. Щиро вдячні, що обрали нашу онлайн - платформу психологічної підтримки!",
    getHelpTitle:         "Обратиться за помощью",
    getHelpLoad:          "Обработка данных",
    getHelpNameLabel:     "Ваше имя",
    getHelpNamePholder:   "Введите имя",
    getHelpSnameLabel:    "Ваша фамилия",
    getHelpSnamePholder:  "Введите фамилию",
    getHelpRadioGender:   "Пол",
    getHelpAdult:         "Подтверждаю, что мне уже есть 18 или больше",
    getHelpTel:           "Номер телефона",
    placeholderPhone:     "Код страны, номер телефона",
    getHelpLabel:         "Что вас беспокоит?",
    areaGetHelpLabel:     "Кратко опишите свой запрос",
    areaGetHelpPholder:   "Начните вводить",
    getHelpFeedBackLabel: "Выберите удобный способ связи",

    getHelpAgreeText:   "Я принимаю Условия конфиденциальности и обработки персональных данных.",
    getHelpAgreeLink:   "Подробнее",
    getHelpTelList:     "По указанному номеру",
    getHelpMale:        "Мужчина",
    getHelpFemale:      "Женщина",
    contentLoading:     "Загружаемся...",
    modalGetTitle:      "Помогите нам помогать вам!",
    modalBefore:        "Наши специалисты работают бесплатно, и мы очень ценим их время. Поэтому призываем вас соблюдать регламент консультаций.",
    modalAfter:         "Наши специалисты готовы предоставить вам от 1 до 3 консультаций. Продолжительность одной консультации - 50 минут. Если психолог связался с вами с помощью смс или звонка для согласования даты и времени, но не получил ответа, обращение аннулируется. Если вы опоздаете на консультацию, ее время сокращается на соответствующее количество минут. Наши специалисты ведут общение только в формате аудио или видеобеседы. Мы считаем обмен электронными письмами или текстовыми сообщениями неэффективным методом консультирования.",
    modalAfterBr:       "Спасибо за понимание.",
    modalAfterBr2:      "Психолог имеет право прервать консультацию в следующих случаях: если пациент под действием наркотических веществ или алкоголя, ведет себя агрессивно или оскорбительно, нарушает порядок во время разговора. Надеемся на ваше понимание и толерантность",
    resFotmOnErr:       "Произошла техническая ошибка",
    resFotmOnErrDesc:   "Мы уже работаем над ее решением.Попробуйте повторить ваши действия через 15 мин.",
    resFotmOnErrNote:   "Если результат будет таким же, напишите нам на почту",
    giveHelpResTitle:   "Ваша форма успешно заполнена",
    giveHelpResDesc:    "Благодарим вас за то, что присоединились к нашей инициативе. С вами обязательно свяжутся наши специалисты сразу после обработки вашей анкеты",
    giveHelpTitle:      "Присоединиться к инициативе",
    giveHelpLoader:     "Обработка данных...",
    giveHelpEmail:      "Электронная почта",
    giveHelpPassword:   "Повторите пароль",
    giveHelpAgree:      "Подробнее",
    giveHelpAgreeText:  "Я принимаю условия конфиденциальности и обработки персональных данных",
    giveHelpEducation:  "Ваше образование и специализация работи ",
    giveHelpEducPehold: "Введите информацию",
    giveHelpSpec:       "Направления",
    giveHelpDownload:   "Выберите файл",
    giveHelpDownEd:     "Добавьте один или несколько документов, подтверждающих квалификацию специалиста",
    giveHelpResume:     "Выберите резюме",
    giveHelpDownResume: "Загрузите Ваше резюме в формате .doc .docx .pdf",
    buttonSubmit:       "Отправить",
    buttonContinue:     "Далее",
    buttonMain:         "На главную",
    fieldSelect:        "Сделайте выбор",
    buttonMore:         "Читать далее",
    buttonLess:         "Свернуть",

    "privacy":        "Конфиденциальность",
    "specialists":    "Специалисты",
    "partners":       "Партнеры",
    "about":          "О нас",
    "howWeWork":      "Как мы работаем",
    "faq":            "FAQ",
    "contacts":       "Контакты",
    "toAdmin":        "Войти",
    "address":        "Адрес",
    "addressLink":    "Институт когнитивного моделирования (ИКМ), ул. Предславинская, 40",
    "social":         "Социальные сети",
    "footerPrivacy1": "Политика конфиденциальности и",
    "footerPrivacy2": "обработка персональных данных",
    "footerRules":    "Правила пользования сайтом",
    "tel":            "Телефон технической поддержки",

    "cookieText":   "Этот сайт использует файлы cookie для повышения удобства работы",
    "cookieLink":   "Подробнее",
    "cookieButton": "Принимаю",

    "homePrivacyTitle": "Только между нами",
    "homePrivacyText":  "Мы понимаем, как трудно иногда бывает открыться постороннему человеку, поэтому все наши пользователи имеют право не сообщать личной информации. Любой разговор и переписка останутся только между нами. Мы не имеем корыстной цели, а искренне хотим помочь вам справиться и пережить трудную ситуацию.",

    "homeHowWeWorkBtn": "Обратиться за поддержкой",

    "homeAboutText1": "Інститут когнітивного моделювання — науково-дослідницька компанія, що надає повний спектр рішень для соціальних комунікацій. Саме нею було створено одні з найпопулярніших в Україні вайбер-каналів «Коронавірус_інфо» та «Школа_інфо».",
    "homeAboutText2": "ІКМ також запустив онлайн-платформу «Розкажи мені». Це сайт, де можна отримати безкоштовні психологічні консультації. Спеціалісти проекту надають допомогу тим, чия нервова система постраждала від наслідків коронавірусу чи інших емоційних потрясінь. Після COVID-19, друга за поширеністю проблема в Україні – домашнє насильство. Від рук домашніх тиранів щороку страждає кілька мільйонів людей! Приниження, знецінення, газлайтинг, обмеження у фінансах, стусани, ляпаси, побиття – все це не є нормою. Тим не менш, кількість людей, які с цим стикаються, жахає.",
    "homeAboutText3": "Впоратися з такою травмою самотужки вкрай складно. А якщо насильство було фізичним та регулярним, без допомоги спеціаліста не обійтися. Аби допомогти потерпілим зробити перший крок до зцілення, Інститут когнітивного моделювання посилює команду психологів платформи «Розкажи мені», що спеціалізуються на роботі з пацієнтами, що пережили насильство. Залишивши заявку, ви матимете змогу отримати три безкоштовні онлайн-консультації терапевта.",
    "homeAboutText4": "Виговоритися хоч комусь та разом почати шукати шляхи до зцілення надзвичайно важливо!",

    "forPsychologistsBtn":        "Стать психологом проекта",
    "forPsychologistsMotivation": "Присоединяйтесь, если хотите улучшать эмоциональное состояние украинцев!",

    "invalidEmpty":             "Поле не может быть пустым",
    "invalidWrongFormatEmail":  "Неправильный формат электронной почты",
    "invalidEmailAlreadyTaken": "Данный email уже занят",
    "invalidPassFormat":        "Пароль должен содержать только цифры, большие и маленькие буквы и минимум 7 символов",
    "invalidPassNoSame":        "Пароль не совпадает",
    "invalidPhoneFormat":       "Неправильный формат телефона",
    "invalidPhoneAlreadyTaken": "Данный телефон уже занят",
    "invalidDocFormat":         "Нужно отправить файл \" doc \" формата",
    "invalidMaxLength":         "Файл не может быть больше 8 МБ",
    "invalidAllowedFormatDoc":  "Разрешены форматы файла - doc, docx, pdf",
    "invalidFileFormat":        "Нужно отправить хотя бы один файл \" png, jpg, pdf \" формата",
    "invalidMax5Files":         "Разрешено не более 5 файлов",
    "invalidAllowedFormatFile": "Разрешены форматы файла - png, jpg, jpeg или pdf",

    "reviewRequired":     " Этот вопрос обязательный",
    "reviewResTitle":     "Благодарим за ответы",
    "reviewResDesc":      "Нам важно ваше мнение. Эта оценка поиможет нам работать лучше",
    "reviewErr1":         "Вы не можете оставить отзыв,",
    "reviewErr2":         "так как психолог не найден",
    "reviewFormTitle":    " Форма обратной связи платформы психологической поддержки «Розкажи мені»",
    "reviewFormDescr":    " Нам очень важно узнать ваше мнение о платформе и роботе наших психологов. Мы хотим предоставлять качественные услуги и улучшать вашу эмоциональную стабильность. Поэтому будем благодарны если вы дадите несколько ответов р работе платформы «Розкажи мені»",
    "reviewReq":          "* Обязательно",
    "reviewQuestion1":    "Вы удовлетворены консультацией?",
    "reviewAnswerPhold":  "Мой ответ",
    "reviewQuestion2":    " Сколько консультаций вам было предоставлено психологом платформы «Розкажи мені»",
    "reviewQuestion3":    " Как вы оцените работу психолога? (по шкале от 1 до 5, где 1 - не понравилось, 5 - очень хорошо)",
    "reviewQuestion4":    "Коротко опишите впечатления от работы с психологом",
    "reviewQuestion5":    "Коротко опишите впечатления от пользования платформой и пожелания ее улучшения",
    "reviewName":         "Анонимный пользователь",
    "popUpTitlePhone":    "Количество запросов в этом месяце исчерпано",
    "popUpMessagePhone":  "Просим вас обратиться за повторной консультацией через 30 дней, ведь количество запросов в этом месяце - исчерпано.",
    "popUpMessagePhone2": "Спасибо за понимание!",
    "popUpMessageToBot":  "Пока Вы ожидаете возможности нового обращения, вы можете пообщаться з чат-ботом \"ДРУГ\"",
    "overLoadMessage":    "Из-за большого количества заявок, которые поступают на платформу, время на обработку вашего обращения психологом увеличено.",
    "overLoadTitle":      "Платформа перегружена!",
    "ifNotAdult":         "Если Вам еще нет 18 лет, обратитесь в Национальную детскую «горячую линию»",
    "or":                 "или",
    "forCalling":         "В будни",
    "informPanel":        "Чтобы выбрать несколько вариантов удерживайте",
    "creator":            "Разаработано:",
    "botHeader1":         "Я – автоматический собеседник, основанный на ",
    "botHeaderGrey":      "когнитивно-поведенческой",
    "botHeader2":         "терапии.",
    "botParagraph":       "Со мной Вы можете получить онлайн-терапию, вести контроль настроения и узнать много нового о себе.",
    "botLink":            "НАЧАТЬ ОБЩЕНИЕ",
    "botMore":            "УЗНАТЬ БОЛЬШЕ",
    "successToBot":       "Пока Вы ожидаете общения с нашим психологом, вы можете пообщаться с автоматизированным",
    "anchorBot":          "чат-ботом \"ДРУГ\"",
};
