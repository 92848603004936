export default {
    partnersTitle:        "Наші однодумці",
    reviewsTitle:         "Враження від платформи",
    specialistsTitle:     "Провідні спеціалісти",
    specialistsDescr:     "Ваша емоційна стабільність – те, чого ми прагнемо досягти. Тому в нашій команді працюють лише фахівці з відповідною сертифікацією, яких відібрали провідні експерти проекту. Кожен із них має багаторічний досвід роботи з постраждалими від насильства.",
    specialistsBtn:       "Звернутися за підтримкою",
    resultTitle:          "Ваш запит успішно зареєстровано.",
    resultDescript:       "Вже скоро з вами зв'яжеться наш спеціаліст. Очікуйте, і ми допоможемо вам досягнути психологічного комфорту.",
    getHelpTitle:         "Звернутися за підтримкою",
    getHelpLoad:          "Обробка даних...",
    getHelpNameLabel:     "Ваше ім'я",
    getHelpNamePholder:   "Введіть ім'я",
    getHelpSnameLabel:    "Ваше прізвище",
    getHelpSnamePholder:  "Введіть прізвище",
    getHelpRadioGender:   "Стать",
    getHelpAdult:         "Підтверджую що мені є 18",
    getHelpTel:           "Номер телефону",
    placeholderPhone:     "Код країни, номер телефону",
    getHelpLabel:         "Від якого насильства ви потерпали/потерпаєте?",
    areaGetHelpLabel:     "Коротко опишіть ваш запит",
    areaGetHelpPholder:   "Опис запиту",
    getHelpFeedBackLabel: "Оберіть найзручніший спосіб зв’язку",
    getHelpRadiochild:  "Ви страждали від насильства в дитинстві?",
    getHelpChildYes:    "Так",
    getHelpChildNo:     "Ні",
    getHelpinPresent:   "Насильство над вами у минулому чи ви потерпаєте від нього і зараз?",
    getHelpAbuser:      "Хто вчиняє над вами насильство?",
    getHelpPast:        "У минулому",
    getHelpPresent:     "Потерпаю зараз",
    getHelpAgreeText:   "Я приймаю умови конфіденційності та обробки персональних даних.",
    getHelpAgreeLink:   "Детальніше",
    getHelpTelList:     "За вказаним номером",
    getHelpMale:        "Чоловік",
    getHelpFemale:      "Жінка",
    contentLoading:     "Завантажуемось...",
    modalGetTitle:      "Ваш запит успішно зареєстровано",
    modalBefore0:       "Наші фахівці вже взяли його в роботу та зв’яжуться з вами найближчим часом. Дякуємо, що довірилися нам!",
    modalBefore:        "Наші фахівці працюють безкоштовно, і ми дуже цінуємо їхній час. Тому закликаємо вас дотримуватися регламенту консультацій.",
    modalAfter:         "Спеціалісти готові надати вам 3 консультації. Тривалість однієї – 50 хвилин. Якщо психолог зв’язався з вами за допомогою смс або дзвінка для узгодження дати й часу, але не отримав відповіді, звернення анулюється. Якщо ви запізнитесь на консультацію, її час скорочується на відповідну кількість хвилин. Наші спеціалісти ведуть спілкування тільки у форматі аудіо- або відеобесіди.",
    modalAfterBr:       " Якщо ж вашому життю та здоров’ю прямо зараз загрожує небезпека – ТЕРМІНОВО телефонуйте у поліцію за номером 102.",
    modalAfterBr2:      "Психолог має право перервати консультацію у наступних випадках: якщо пацієнт під дією наркотичних речовин чи алкоголю, поводиться агресивно чи образливо, порушує порядок під час розмови. Сподіваємось на ваше розуміння та толерантність.",
    resFotmOnErr:       "Сталася технічна помилка",
    resFotmOnErrDesc:   "Ми вже працюємо над її вирішенням. Спробуйте повторити ваші дії через 15 хвилин.",
    resFotmOnErrNote:   "Якщо результат буде таким же, то напишіть нам на пошту",
    giveHelpResTitle:   "Ваша форма успішно заповнена",
    giveHelpResDesc:    "Дякуємо вам за те, що долучилися до нашої ініціативи. З вами обов'язково зв’яжуться наші фахівці одразу після обробки анкети.",
    giveHelpTitle:      "Долучитися до ініціативи",
    giveHelpLoader:     "Обробка даних...",
    giveHelpEmail:      "Електронна пошта",
    giveHelpPassword:   "Повторіть пароль",
    giveHelpAgree:      "Детальніше",
    giveHelpAgreeText:  "Я приймаю умови конфіденційності та обробки персональних даних",
    giveHelpEducation:  "Ваша освіта та спеціалізація роботи",
    giveHelpEducPehold: "Введіть інформацію",
    giveHelpSpec:       "Напрямки",
    giveHelpDownload:   "Оберіть файл",
    giveHelpDownEd:     "Додайте один або декілька документів, що підтверджує вашу кваліфікацію спеціаліста",
    giveHelpResume:     "Оберіть резюме",
    giveHelpDownResume: "Завантажте Ваше резюме в форматі .doc .docx .pdf",
    buttonSubmit:       "Відправити",
    buttonContinue:     "Далі",
    buttonMain:         "На головну",
    fieldSelect:        "Вид насильства",
    fieldSelect2:       "Можливість обрати кілька",
    fieldSelect1:       "Хто вчиняє",
    buttonMore:         "Читати більше",
    buttonLess:         "Згорнути",

    "privacy":        "Конфідеційність",
    "specialists":    "Спеціалісти",
    "partners":       "Партнери",
    "about":          "Про нас",
    "howWeWork":      "Як ми працюємо",
    "faq":            "FAQ",
    "contacts":       "Контакти",
    "toAdmin":        "Увійти",
    "address":        "Адреса",
    "addressLink":    "Інститут глобальної трансформації (Institute for Global Transformation), вул. Предславинська, 40",
    "social":         "Соціальні мережі",
    "footerPrivacy1": "Політика конфіденційності та",
    "footerPrivacy2": "обробка персональних даних",
    "footerRules":    "Правила користування сайтом",
    "tel":            "Телефон технічної підтримки",

    "cookieText":   "Цей веб-сайт використовує файли cookie для підвищення зручності роботи з веб-сайтом",
    "cookieLink":   "Детальніше",
    "cookieButton": "Приймаю",

    "homePrivacyTitle": "Тільки між нами",
    "homePrivacyText":  "Ми розуміємо, як важко іноді буває відкритися сторонній людині, тому всі, хто звернеться до нас за допомогою, мають право не повідомляти особисту інформацію. Будь-яка розмова і листування залишаться тільки між нами. Ми не маємо корисливої мети, а щиро хочемо допомогти вам упоратись із труднощами і пережити важку ситуацію",

    "homeHowWeWorkBtn": "Звернутися за підтримкою",

    "homeAboutText1": "Компанія Інститут глобальної трансформації (Institute for Global Transformation)",
    "homeAboutText2": "IGT також запустив онлайн-платформу «Розкажи мені». Це сайт, де можна отримати безкоштовні психологічні консультації. Спеціалісти проекту надають допомогу тим, чия нервова система постраждала від наслідків коронавірусу чи інших емоційних потрясінь. Після COVID-19, друга за поширеністю проблема в Україні - домашнє насильство. Від рук домашніх тиранів щороку страждає кілька мільйонів осіб. Приниження, знецінення, газлайтинг, обмеження у фінансах, стусани, ляпаси, побиття - все це не є нормою. Втім, кількість людей, які із цим стикаються - жахає. ",
    "homeAboutText3": "Впоратися із такою травмою самотужки вкрай складно. А якщо насильство було фізичним та регулярним, без допомоги спеціаліста не обійтися. Аби допомогти потерпілим зробити перший крок до зцілення, Інститут глобальної трансформації посилює команду психологів платформи «Розкажи мені», що спеціалізуються на роботі з пацієнтами, які пережили насильство. Залишивши заявку, ви матимете змогу отримати три безкоштовні онлайн-консультації терапевта.",
    "homeAboutText4": "Виговоритися хоч комусь та разом почати шукати шляхи до зцілення надзвичайно важливо!",

    "forPsychologistsBtn":        "Стати психологом проекту",
    "forPsychologistsMotivation": "Приєднуйтеся, якщо хочете покращувати емоційний стан українців!",

    "invalidEmpty":             "Поле не може бути порожнім",
    "invalidWrongFormatEmail":  "Неправильний формат електронної пошти",
    "invalidEmailAlreadyTaken": "Даний email вже зайнятий",
    "invalidPassFormat":        "Пароль повинен містити тільки цифри, великі та маленькі літери і мінімум 7 символів",
    "invalidPassNoSame":        "Пароль не збігається",
    "invalidPhoneFormat":       "Неправильний формат телефону",
    "invalidPhoneAlreadyTaken": "Даний телефон вже зайнятий",
    "invalidDocFormat":         "Потрібно відправити файл \"doc\" формату",
    "invalidMaxLength":         "Файл не може бути більше 8 МБ",
    "invalidAllowedFormatDoc":  "Дозволені формати файлу - doc, docx, pdf",
    "invalidFileFormat":        "Потрібно відправити хоча б один файл \"png, jpg, pdf\" формату",
    "invalidMax5Files":         "Дозволено не більше 5 файлів",
    "invalidAllowedFormatFile": "Дозволені формати файлу - png, jpg, jpeg або pdf",

    "reviewRequired":     " Це питання обов’язкове",
    "reviewResTitle":     "Дякуємо за відповіді",
    "reviewResDesc":      "Для нас важлива ваша думка. Ця оцінка допоможе нам працювати краще",
    "reviewErr1":         "Ви не можете залишити відгук,",
    "reviewErr2":         "тому що психолога не знайдено",
    "reviewFormTitle":    " Форма зворотного зв’язку платформи психологічної підтримки «Розкажи мені»",
    "reviewFormDescr":    " Нам дуже важливо дізнатися вашу думку про платформу та роботу наших психологів. Ми хочемо надавати якісні послуги та покращувати вашу емоційну стабільність. Тому будемо вдячні, якщо ви дасте відповідь на кілька запитань щодо роботи платформи «Розкажи мені»",
    "reviewReq":          "* Обов'язково",
    "reviewQuestion1":    "Ви задоволені консультацією?",
    "reviewAnswerPhold":  "Моя відповідь",
    "reviewQuestion2":    " Скільки консультацій вам було надано психологом платформи «Розкажи мені»",
    "reviewQuestion3":    " Як ви оціните роботу психолога? (за шкалою від 1 до 5, де 1 - не сподобалося, 5 - дуже добре)",
    "reviewQuestion4":    "Коротко опишіть враження від роботи з психологом",
    "reviewQuestion5":    "Коротко опишіть враження від користування платформою і побажання її вдосконалення",
    "reviewName":         "Анонімний користувач",
    "popUpTitlePhone":    "Кількість запитів в цьому місяці вичерпано",
    "popUpMessagePhone":  "Просимо вас звернутись за повторною консультацією через 30 днів, адже кількість запитів цього місяця - вичерпано.",
    "popUpMessagePhone2": "Дякуємо за розуміння!",
    "popUpMessageToBot":  "Поки Ви очікуєте на можливість нового звернення, ви можете поспілкуватися з",
    "overLoadMessage":    "Через велику кількість заявок, які надходять на платформу, час на опрацювання вашого звернення психологом збільшено.",
    "overLoadTitle":      "Платформу перевантажено!",
    "ifNotAdult":         "Якщо Вам ще не виповнилось 18 років, зверніться на Національну дитячу «гарячу лінію»",
    "or":                 "або",
    "forCalling":         "У будні",
    "informPanel":        "Для того щоб обрати декілька варіантів - утримуйте",
    "creator":            "Розроблено:",
    "botHeader1":         "Я – автоматизований співрозмовник, заснований на ",
    "botHeaderGrey":      "когнітивно-поведінковій",
    "botHeader2":         "терапії.",
    "botParagraph":       "Разом зі мною Ви можете отримати онлайн-терапію, вести контроль настрою та дізнаватися багато нового про себе.",
    "botLink":            "ПОЧАТИ СПІЛКУВАННЯ",
    "botMore":            "ДІЗНАТИСЯ БІЛЬШЕ",
    "successToBot":       "Поки ви очікуєте на спілкування із нашим психологом, то можете поспілкуватися з автоматизованим",
    "anchorBot":          " чат-ботом \"ДРУГ\"",
};
